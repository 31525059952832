 import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom'; 
import logo from './image/logo_hbalza_actual.png';
import logoSaludZulia from './image/logo_salud_zulia.png'
import iconRs1 from './image/rs1.png';
import iconRs2 from './image/rs2.png';
import iconRs3 from './image/rs3.png';
import iconRs4 from './image/rs4.png';
import iconRs5 from './image/rs5.png';
import iconRs6 from './image/rs6.png';

import Home from './app/home/home';



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={logoSaludZulia} className="App-logo" alt="logo" />
        {/* <div> 
            <h4><a href='https://www.hbalzaconsultores.com/inicio' target="_blank">Centro de ayuda</a></h4>
        </div> */}
      </header>  
         <Routes>
             <Route path="/" element={ <Home /> }/>  

              {/* <Route path="informacion" element={ <Articulos /> } onEnter={() => console.log('Entered /')}  />   */}
         </Routes>   
      <div className='footer'>
          <div className='text-center'>
            <div className="d-flex justify-content-center g-5 mb-3" style={{gap: '15px'}}>
              <a href='https://www.youtube.com/channel/UCiiyUEM8DjkBVpfznIOvWXQ' target="_blank">
                <img src={iconRs1} width={40} height={40} alt="logo" /> 
              </a>
              <a href='mailto:hola@hbalzaconsultores.com' target="_blank">
                <img src={iconRs2} width={40} height={40} alt="logo" /> 
              </a>
              <a href='https://facebook.com/hbalzaconsultores/' target="_blank">
                <img src={iconRs3} width={40} height={40} alt="logo" /> 
              </a>
              <a href='https://www.instagram.com/hbalzaconsultores/' target="_blank">
                <img src={iconRs4} width={40} height={40} alt="logo" /> 
              </a>
              <a href='https://x.com/Hbalzaconsulto1' target="_blank">
                <img src={iconRs5} width={40} height={40} alt="logo" /> 
              </a>
              <a href='https://www.threads.net/@hbalzaconsultores' target="_blank">
                <img src={iconRs6} width={40} height={40} alt="logo" /> 
              </a>
            </div>
            <h5 className='fontW400'>Todos los derechos reservados / Hbalza Consultotes & Negocios </h5>  
          </div>
      </div>
    </div>
  );
}

export default App;
