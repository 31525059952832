import './Loading.css';  

export function Loading({loading, text=''}) {


    return (
        <>
   
            {
                loading ?  (
                    <div className='content-l'> 
                        <div>
                            <div className="loader mx-auto"></div> 
                            <h5 className='mt-4'>{text}</h5>
                        </div>
                    </div>
                ) : <></>
            }
         
        
        </>
    )

}