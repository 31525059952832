import axios from "axios"; 

const instance = axios.create({
  baseURL: "https://v2.alliance-cloud.com/api/api/hbalza", 
  timeout: 60000,
  headers: { 
    "Accept": "application/json",
    "Content-Type": "application/json"
  }
});   

export default instance;