import { Alert, Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import './home.css'; 
import searchIcon from '../../svg/icons-busqueda.png'
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import api from '../../api/api' 
import { Loading } from '../login/Loading';
import zelleIcon from '../../image/unnamed.png' 
import mercantilIcon from '../../image/mercantilICON.png' 
import copyIcon from '../../svg/icons-copy.svg'  
import warningIcon from '../../image/warning-icon.png'  
import succesIcon from '../../image/succes-icon.png'  



export default function Home() { 

    const [inputValue, setInputValue] = useState('');
    const [referencia, setReferencia] = useState('');
    const [cliente, setCliente] = useState();
    const [loading, setLoading] = useState(false);
    const [select, setSelect] = useState();
    const [selectInfo, setSelectInfo] = useState();
    const [clienteNotFound, setClienteNotFound] = useState(false);
    const [titular, setTitular] = useState('');


    const [modalInfo, setModalInfo] = useState();
    const [showModal, setShowModal] = useState(false);

 
    const fetchData = async () => {
        setClienteNotFound(false);
        try {
            setLoading(true);
            if(inputValue != "") { 
                const clienteResponse = await   api.get('/cliente', {
                    params: {
                        codigo: inputValue
                    }
                })  
                setCliente(clienteResponse.data); 
            }
            setLoading(false);
        } catch (error) {  
            setCliente(null);
            setLoading(false);
            if(error) {
                if (error.response ) {
                switch(error.response.status) { 
                    case 412:  
                    setClienteNotFound(true)
                    setTimeout(() => {
                        setClienteNotFound(false);
                    }, 10000);
                    break
                    default: 
                        console.log('Revisar algo paso '); 
                    break
                }
            }
            }
          } 
    }; 
    
    const sendPago = async () => {

        try {
            setLoading(true);
            const response = await   api.post('/procesarPago', {
                SubsidiaryAccount: cliente.codigo,
                EntryDate: new Date(), 
                Description: select == 'zelle' ? titular : 'Nuevo pago',
                Amount: select == 'zelle' ? cliente.saldos.USD : cliente.saldos.VES,
                Currency: select == 'zelle' ? 'USD' : 'VES', 
                venta: true,
                pago: {
                    tipoMov: 'TR',
                    numeroMov: referencia,
                    SubsidiaryAccount: selectInfo,
                }    
            })   

            setModalInfo({
                type: 'succes',
                titulo: 'Reporte de pago exitosa',
                parrafo: 'El proceso de verificación bancaria tomará cerca de 3 días hábiles.',
            }) 

            setShowModal(true);
            setLoading(false);


        } catch (error) {
            setLoading(false);
            if(error) { 
                if(error.response?.data?.errors['pago.numeroMov']) {
                    setModalInfo({
                        type: 'error',
                        titulo: 'Número de referencia duplicado',
                        parrafo: 'El número de referencia que ingresaste ya existe. Por favor, verifica el número y vuelve a intentarlo. Si el problema persiste, contáctanos.',
                    })  
                    setShowModal(true); 
                    // reset();
                } else {
                    if (error.response ) {
                        switch(error.response.status) { 
                            case 412:  
                                setModalInfo({
                                    type: 'error',
                                    titulo: "Lo sentimos, ha ocurrido un error",
                                    parrafo: "Por favor, intenta realizar la acción nuevamente. Si el problema persiste, comunícate con nuestro equipo de soporte para que podamos ayudarte."  })  
                                setShowModal(true); 
                            break
                            default: 
                                console.log('Revisar algo paso '); 
                            break
                        }
                    }
                }
            }
        }

    }
  
    const copyText = async(texto) => {  
              try {
                await navigator.clipboard.writeText(texto);
                console.log('Contenido copiado al portapapeles');
              } catch (err) {
                console.error('Error al copiar: ', err);
              } 
    }

    useEffect(() => {
        if(select) {
            switch(select) {
                case 'zelle':
                    if(cliente.cliente.gruposClientesID === 1) {
                        setSelectInfo('01');
                    } 
                    if(cliente.cliente.gruposClientesID === 2) {
                        setSelectInfo('03');
                    }
                break
                case 'pm':
                    setSelectInfo('06'); 
                break
                case 'tf':
                    setSelectInfo('06'); 
                break
            }
        } 
    }, [select])
  
    const reset = () => {  
        setCliente();
        setSelect();
        setInputValue();
        setReferencia();
        setTitular();
        setSelectInfo();
    }

    const closeModal = () => {
        if(modalInfo.type === 'succes') {
            reset();
            setShowModal(false);
        } else {
            setShowModal(false);
        }
    }

    return ( 
        <div className='content'> 
            <Col xs={10} md={4} lg={3}    >
                <InputGroup className="mb-3" size="sm">
                    <Form.Control
                        placeholder="Ingrese Cédula / RIF"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2" 
                        type="text" 
                        disabled={loading}
                        maxLength={15}
                        value={inputValue}  
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <Button variant="light" id="button-addon2" disabled={inputValue == ""} className='boton' onClick={fetchData}>
                        <img src={searchIcon} />
                    </Button>
                </InputGroup>
                {
                    clienteNotFound && 
                    <Alert  variant={'warning'}>
                        Cliente no encontrado
                    </Alert>
                }
                <Loading loading={loading} text="Cargando..."/> 
                {
                    cliente &&
                        <Row className='d-flex justify-content-between m-0'>
                            <Col xs={12} className='text-start border-bottom-1'>{cliente.nombre}</Col> 
                            <Col xs={6} className='text-start py-2'>Monto a pagar en $:</Col>
                            <Col xs={6} className='text-end py-2'>
                                <CurrencyFormat value={cliente.saldos?.USD ?? 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <b>{value}</b>} /> 
                            </Col> 
                            <Col xs={6} className='text-start py-2'>Monto a pagar en Bs:</Col>
                            <Col xs={6} className='text-end py-2'>
                                <CurrencyFormat value={cliente.saldos?.VES ?? 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'Bs'} renderText={value => <b>{value}</b>} /> 
                            </Col> 
                            <Col xs={12} className='text-center border-top-1 pt-3'>
                               <b>Reportar pago</b>  
                            </Col>
                            <Col xs={12} className='px-0 mt-2'>
                                <Row className='d-flex justify-content-between m-0  ' >
                                        <Col xs={4} className='px-2'>
                                            <div  className={`text-center py-2 boton-pago ${select == 'zelle' ? 'select' : ''}`} onClick={() => setSelect('zelle')}>
                                                Zelle
                                            </div>
                                        </Col>
                                        <Col xs={4} className='px-2'>
                                            <div  className={`text-center py-2 boton-pago ${select == 'pm' ? 'select' : ''}`} onClick={() => setSelect('pm')}> 
                                                 Pago Móvil
                                            </div>
                                        </Col>
                                        <Col xs={4}  className='px-2'>
                                            <div  className={`text-center py-2 boton-pago ${select == 'tf' ? 'select' : ''}`} onClick={() => setSelect('tf')}> 
                                                 Transferencia bancaria
                                            </div> 
                                        </Col> 
                                </Row>
                            </Col>  
                            {
                                select === 'zelle' &&
                                <Col xs={12} className='mt-4' style={{fontSize: '14px'}}>
                                    {
                                        cliente.cliente.gruposClientesID === 1 && 
                                        <Row className='d-flex justify-content-between m-0 align-items-center mb-3' >
                                            <Col xs={2}  >
                                                <img src={zelleIcon} className='imgBankIcon'/>  
                                            </Col>
                                            <Col xs={10} className='text-start'  >
                                                <b>hbalzaconsultores1@gmail.com <img src={copyIcon} className='copy-icon' onClick={() => copyText('hbalzaconsultores1@gmail.com')}/></b>
                                                <p className='mb-0'>BS BUSINESS GROUP LLC <img src={copyIcon} className='copy-icon' onClick={() => copyText('BS BUSINESS GROUP LLC')}/></p>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        cliente.cliente.gruposClientesID === 2 && 
                                        <Row className='d-flex justify-content-between m-0  align-items-center mb-3' >
                                        <Col xs={2}  >
                                            <img src={zelleIcon} className='imgBankIcon'/>  
                                        </Col>
                                        <Col xs={10} className='text-start' >
                                            <b>hbalzaconsultores3@gmail.com <img src={copyIcon} className='copy-icon' onClick={() => copyText('hbalzaconsultores3@gmail.com')}/></b>
                                            <p className='mb-0'>BS BUSINESS GROUP LLC <img src={copyIcon} className='copy-icon' onClick={() => copyText('BS BUSINESS GROUP LLC')}/></p>
                                        </Col>
                                    </Row> 
                                    }
                                  
                                </Col>
                            } 
                               {
                                select === 'pm' &&
                                <Col xs={12} className='mt-4' style={{fontSize: '14px'}}>
                                    <Row className='d-flex justify-content-between m-0 align-items-center mb-3' >
                                        <Col xs={2}  >
                                            <img src={mercantilIcon} className='imgBankIcon'/>  
                                        </Col>
                                        <Col xs={10} className='text-start'  >
                                            <b>0412-2478331 <img src={copyIcon} className='copy-icon' onClick={() => copyText('0412-2478331')}/></b>
                                            <p  className='mb-0'><b>J-297655948</b> <img src={copyIcon} className='copy-icon' onClick={() => copyText('J-297655948')}/></p>
                                            <p className='mb-0'>BANCO MERCANTIL</p>
                                        </Col>
                                    </Row> 
                                </Col>
                            }
                            {
                                select === 'tf' &&
                                <Col xs={12} className='mt-4' style={{fontSize: '14px'}}>
                                    <Row className='d-flex justify-content-between m-0 align-items-center mb-3' >
                                        <Col xs={2}  >
                                            <img src={mercantilIcon} className='imgBankIcon'/>  
                                        </Col>
                                        <Col xs={10} className='text-start'  >
                                            <b>0105-0265-95-1265-06202-1 <img src={copyIcon} className='copy-icon' onClick={() => copyText('0105-0265-95-1265-06202-1')}/></b>
                                            <p className='mb-0'>V-13100729 <img src={copyIcon} className='copy-icon' onClick={() => copyText('V-13100729')}/></p>
                                            <p className='mb-0'>Dulys Sánchez</p>
                                        </Col>
                                    </Row> 
                                </Col>
                            } 

                            {
                                select && 
                                    <Col xs={12} className='mt-2'>
                                        <Form.Control
                                                placeholder="Numero de transferencia y/o referencia"
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2" 
                                                type="text" 
                                                disabled={loading}
                                                maxLength={16}
                                                value={referencia}  
                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                onChange={(e) => setReferencia(e.target.value)}
                                            />
                                    </Col>
                            }

{
                                select === 'zelle'&& 
                                    <Col xs={12} className='mt-2'>
                                        <Form.Control
                                                placeholder="Titular" 
                                                type="text" 
                                                disabled={loading}
                                                maxLength={16}
                                                value={titular}   
                                                onChange={(e) => setTitular(e.target.value)}
                                            />
                                    </Col>
                            }

                            {
                                select   && 
                                    <Col xs={12} className='mt-2'>
                                           <div className="d-grid gap-2">
                                            
                                                <Button variant="primary" id="button-addon2" disabled={((referencia == "" || referencia.length <= 7) && select !== 'zelle' ) || loading}  onClick={sendPago} >
                                             Reportar
                                        </Button>

                                            </div>
                                    </Col>
                            }

   
                        </Row>
                }
            </Col> 

            <Modal show={showModal} centered> 
                <Modal.Body className="d-flex justify-content-center">
                        <div className="text-center">   
                            <h4>{modalInfo?.titulo}</h4>
                            <h6 className="fw-light">{modalInfo?.parrafo}</h6> 
                            {
                                modalInfo?.type === 'succes' && <img src={succesIcon} width={65} className="mt-3" alt="icon"/> 
                            }
                             {
                                modalInfo?.type === 'error' && <img src={warningIcon} width={65} className="mt-3" alt="icon"/>  
                            }
                            <br/>
                            
                            <Button variant="primary" className="mt-3" onClick={closeModal} >Cerrar</Button>
                        </div>
                </Modal.Body> 
            </Modal>
        </div>
    ) 
}

 